import React, { useState, useEffect } from 'react';
import { View, ScreenSpinner, AdaptivityProvider, AppRoot, ConfigProvider, SplitLayout, SplitCol } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import BOARD from './PANELS/BOARD';

const App = () => {
	const [activePanel, setActivePanel] = useState('BOARD');
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);

	useEffect(() => {
		setPopout(null);
	}, []);


	return (
		<ConfigProvider appearance='dark'>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout popout={popout}>
						<SplitCol>
							<View activePanel={activePanel}>
								<BOARD id='BOARD' />
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
}

export default App;
