import React, { Fragment, useEffect, useState } from 'react';

import {
	Panel,
	PanelHeader,
	Cell,
	SimpleCell,
	Group,
	InfoRow,
	Div,
	Card,
	HorizontalCell,
	HorizontalScroll,
	Accordion,
	Counter,
	Title,
	Tabs,
	TabsItem,
	Spacing,
	Separator,
	Tabbar,
	FixedLayout,
	FormItem,
	SegmentedControl,
	Button,
	ButtonGroup,
	IconButton
} from '@vkontakte/vkui';

import { Icon16Delete, Icon16Pause, Icon16Play, Icon16Replay } from '@vkontakte/icons';
import { useGlobalValue } from 'elum-state';
import { SOCKET } from '../ATOMS/index.js';


const infoStyle = { color: 'var(--vkui--color_text_subhead)' };



const getTimeStr = ({ tmp }) => new Date(tmp).toLocaleString('ru-RU', {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	second: 'numeric'
});

const BOARD = ({ id }) => {
	const socket = useGlobalValue(SOCKET);

	const [queues, setQueues] = useState([]);
	const [tabItem, setTabItem] = useState('all');

	useEffect(() => {

		socket.on("connect", () => console.log({ connect: socket.id }));
		socket.on("queues", queues => setQueues(queues));
		socket.on("disconnect", () => console.log({ disconnect: socket.id }));
		// setInterval(() => {
		// 	socket.emit('getQueuesByStatus', { status: tabItem })
		// }, 3000);

	}, []);
	useEffect(() => socket.emit('getQueuesByStatus', { status: tabItem }), [tabItem])

	const QueueItems = () => {
		return queues.filter(({ status }) => tabItem === 'all' ? true : status === tabItem).map(({
			uuid, delivered_at, work_uuid, source, status, model, type, error_reason, vk_user_id, peer_id, created_at, started_at, ended_at, request, response, message_id_for_reply, message_id_of_sent, work_info, user_info, attempts, max_attempts
		}) =>
			<Card key={uuid} mode='tint' style={{ marginTop: 12 }}>
				<HorizontalScroll>
					<div style={{ display: 'flex' }}>
						<Fragment ><HorizontalCell style={{ marginRight: -45, marginLeft: -15, zIndex: 100 }} size="l" >
							<Div >
								<IconButton onClick={() => socket.emit('deleteQueueByUuid', { uuid })}><Icon16Delete /></IconButton>
								{['error', 'processing', 'delivering', 'delivered'].includes(status) &&
									<IconButton
										onClick={() => socket.emit('changeQueueStatusByUuid', { status: 'queue', uuid })}
									><Icon16Replay /></IconButton>
								}
								{status === 'pause' &&
									<IconButton
										onClick={() => socket.emit('changeQueueStatusByUuid', { status: 'queue', uuid })}
									><Icon16Play /></IconButton>
								}
								{['error', 'processing', 'delivering', 'queue'].includes(status) &&
									<IconButton onClick={() => socket.emit('changeQueueStatusByUuid', { status: 'pause', uuid })}>
										<Icon16Pause />
									</IconButton>}
							</Div>
						</HorizontalCell>
							<HorizontalCell size="l" >
								<Div>
									<SimpleCell >
										<InfoRow header="Создан">{getTimeStr({ tmp: created_at })}</InfoRow>
									</SimpleCell>
									<SimpleCell>
										<InfoRow header="Принят в работу">{getTimeStr({ tmp: started_at })}</InfoRow>
									</SimpleCell>
									<SimpleCell>
										<InfoRow header="Завершен">{getTimeStr({ tmp: ended_at })}</InfoRow>
									</SimpleCell>

								</Div>
							</HorizontalCell>
							<HorizontalCell size="l" >
								<Div>
									<SimpleCell>
										<InfoRow header="Статус"><span style={{ color: status === 'error' ? 'red' : 'green' }}>{status.toUpperCase()}</span></InfoRow>
									</SimpleCell>
									<SimpleCell>
										<InfoRow header="ID пользователя">{vk_user_id}</InfoRow>
									</SimpleCell>
									<SimpleCell>
										<InfoRow header="UUID">{uuid.slice(0, 8)}</InfoRow>
									</SimpleCell>
								</Div>
							</HorizontalCell>
							<HorizontalCell size="l" >
								<Div>
									<SimpleCell >
										<InfoRow header="Модель">{model.toUpperCase()}</InfoRow>
									</SimpleCell>
									<SimpleCell>
										<InfoRow header="Тип">{type.toUpperCase()}</InfoRow>
									</SimpleCell>
									<SimpleCell>
										<InfoRow header="Источник">{source.toUpperCase()}</InfoRow>
									</SimpleCell>
								</Div>
							</HorizontalCell>
							<HorizontalCell size="l">
								<Div>
									<SimpleCell multiline>
										<InfoRow header="Отправлено с беседы">{peer_id !== vk_user_id ? 'TRUE' : 'FALSE'}</InfoRow>
									</SimpleCell>
									<SimpleCell>
										<InfoRow header="ID сообщения для ответа">{message_id_for_reply}</InfoRow>
									</SimpleCell>
									{message_id_of_sent ? <SimpleCell>
										<InfoRow header="ID доставленного сообщения">{message_id_of_sent}</InfoRow>
									</SimpleCell> : <></>}
								</Div>
							</HorizontalCell>
							<HorizontalCell size="l">
								<Div>
									<SimpleCell multiline>
										<InfoRow header="Макс. кол-во попыток">{max_attempts}</InfoRow>
									</SimpleCell>
									<SimpleCell multiline>
										<InfoRow header="Попыток">{attempts}</InfoRow>
									</SimpleCell>
									{work_uuid ? <SimpleCell>
										<InfoRow header="work_uuid">{work_uuid.slice(0, 8)}</InfoRow>
									</SimpleCell> : request.length < 150 && <SimpleCell>
										<InfoRow header="Текст запроса">{request}</InfoRow>
									</SimpleCell>}
								</Div>
							</HorizontalCell>
							<HorizontalCell size="l">
								<Div>
									{delivered_at !== created_at && <SimpleCell>
										<InfoRow header="Доставлен">{getTimeStr({ tmp: delivered_at })}</InfoRow>
									</SimpleCell>}
									{response && <Accordion>
										<Accordion.Summary iconPosition="before">Текст ответа</Accordion.Summary>
										<Div style={infoStyle}>{response}</Div>
									</Accordion>}
									{request.length > 150 && <Accordion>
										<Accordion.Summary iconPosition="before">Текст запроса</Accordion.Summary>
										<Div style={infoStyle}>{request}</Div>
									</Accordion>}
									{status === 'error' && <Accordion>
										<Accordion.Summary iconPosition="before">Причина ошибки</Accordion.Summary>
										<Div style={infoStyle}>{error_reason}</Div>
									</Accordion>}
									{work_info !== '' && <Accordion>
										<Accordion.Summary iconPosition="before">Информация о работе</Accordion.Summary>
										<Div style={infoStyle}>{work_info}</Div>
									</Accordion>}
								</Div>
							</HorizontalCell>
						</Fragment>
					</div>
				</HorizontalScroll>

			</Card>
		)
	}

	return (
		<Panel id={id}>
			<PanelHeader>DASHBOARD</PanelHeader>

			<Div>
				<Group >
					<Title level="2" style={{ marginBottom: 12 }}>Очередь сообщений</Title>
					<Tabs mode='accent'>
						<HorizontalScroll>
							<div style={{ display: 'flex' }}>
								<HorizontalCell size="l" >
									<TabsItem
										after={<Counter size='s' mode='primary'>{queues.length}</Counter>}
										onClick={() => setTabItem('all')} selected={tabItem === 'all'}>Все</TabsItem>
								</HorizontalCell>
								<HorizontalCell size="l" >

									<TabsItem
										after={<Counter size='s' mode='primary'>{queues.filter(({ status }) => status === 'queue').length}</Counter>}
										onClick={() => setTabItem('queue')} selected={tabItem === 'queue'}>В ожидание</TabsItem>


								</HorizontalCell>
								<HorizontalCell size="l" >
									<TabsItem
										after={<Counter size='s' mode='secondary'>{queues.filter(({ status }) => status === 'processing').length}</Counter>}
										selected={tabItem === 'processing'}
										onClick={() => setTabItem('processing')}
									>Выполняются</TabsItem>
								</HorizontalCell>
								<HorizontalCell size="l" >
									<TabsItem
										after={<Counter size='s' mode='inherit'>{queues.filter(({ status }) => status === 'completed').length}</Counter>}
										selected={tabItem === 'completed'}
										onClick={() => setTabItem('completed')}
									>Выполнены</TabsItem>

								</HorizontalCell>
								<HorizontalCell size="l" >
									<TabsItem
										after={<Counter size='s' mode='inherit'>{queues.filter(({ status }) => status === 'delivering').length}</Counter>}
										selected={tabItem === 'delivering'}
										onClick={() => setTabItem('delivering')}
									>Доставляются</TabsItem>

								</HorizontalCell>
								<HorizontalCell size="l" >
									<TabsItem
										after={<Counter size='s' mode='inherit'>{queues.filter(({ status }) => status === 'delivered').length}</Counter>}
										selected={tabItem === 'delivered'}
										onClick={() => setTabItem('delivered')}
									>Доставлены</TabsItem>

								</HorizontalCell>
								<HorizontalCell size="l" >
									<TabsItem
										after={<Counter size='s' mode='prominent'>{queues.filter(({ status }) => status === 'error').length}</Counter>}
										selected={tabItem === 'error'}
										onClick={() => setTabItem('error')}
									>Ошибки</TabsItem>
								</HorizontalCell>
								<HorizontalCell size="l" >
									<TabsItem
										after={<Counter size='s' mode='primary'>{queues.filter(({ status }) => status === 'pause').length}</Counter>}
										selected={tabItem === 'pause'}
										onClick={() => setTabItem('pause')}
									>В паузе</TabsItem>
								</HorizontalCell>
							</div>
						</HorizontalScroll>



					</Tabs>
					<Tabs mode='secondary'>
						<HorizontalScroll>
							<div style={{ display: 'flex' }}>
								<HorizontalCell size="l" >
								<TabsItem onClick={() => socket.emit('deleteQueueByStatus', { status: tabItem })} after={<Icon16Delete />}>Удалить все</TabsItem>

								</HorizontalCell>
								<HorizontalCell size="l" >
								{['processing', 'error', 'delivering'].includes(tabItem) && <TabsItem onClick={() => socket.emit('changeStatusByStatus', { status: tabItem, newStatus: 'queue' })} after={<Icon16Replay />}>Повторить все</TabsItem>}

								</HorizontalCell>
								<HorizontalCell size="l" >
								{!['pause', 'delivered', 'all', 'completed'].includes(tabItem) && <TabsItem onClick={() => socket.emit('changeStatusByStatus', { status: tabItem, newStatus: 'pause' })} after={<Icon16Pause />}>Остановить все</TabsItem>}

								</HorizontalCell>
								<HorizontalCell size="l" >
								{['pause'].includes(tabItem) && <TabsItem onClick={() => socket.emit('changeStatusByStatus', { status: tabItem, newStatus: 'queue' })} after={<Icon16Play />}>Запустить все</TabsItem>}

								</HorizontalCell>
							</div>
						</HorizontalScroll>

					</Tabs>
					<QueueItems />


				</Group>

			</Div>


		</Panel >
	)
}



export default BOARD;
