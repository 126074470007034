import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import App from "./App";

// Init VK  Mini App
bridge.send("VKWebAppInit");

new URLSearchParams(window.location.search).get('token') && ReactDOM.render(<App />, document.getElementById("root"));
import("./eruda").then(({ default: eruda }) => {}); //runtime download
